const fonts = [
  {
    name: 'Playfair Display',
    style: "'Playfair Display', serif",
    description: 'Elegant serif font'
  },
  {
    name: 'Space Mono',
    style: "'Space Mono', monospace",
    description: 'Modern monospace font'
  },
  {
    name: 'Montserrat',
    style: "'Montserrat', sans-serif",
    description: 'Clean sans-serif font'
  },
  {
    name: 'Roboto Mono',
    style: "'Roboto Mono', monospace",
    description: 'Technical monospace font'
  },
  {
    name: 'Oswald',
    style: "'Oswald', sans-serif",
    description: 'Condensed sans-serif font'
  }
];

export const getFontByTime = () => {
  const now = new Date();
  const timeString = now.toISOString();
  const hash = timeString.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  const index = Math.abs(hash) % fonts.length;
  return fonts[0];
}; 