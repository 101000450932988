import React, { useRef, useEffect } from 'react';
import './App.css';
import TypewriterText from './TypewriterText';
import SplitFlapCounter from './SplitFlapCounter';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBufeCHK9f_KnPBvfkLvCbxNBExrOnRNgQ",
  authDomain: "clbi-website.firebaseapp.com",
  projectId: "clbi-website",
  storageBucket: "clbi-website.firebasestorage.app",
  messagingSenderId: "930831858383",
  appId: "1:930831858383:web:cabebc13f4fecf5d80d5ba",
  measurementId: "G-JXG56ZJ8CV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Video autoplay failed:", error);
      });
    }
  }, []);

  return (
    <div className="App">
      <video
        ref={videoRef}
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src="./static/movies/butterfly.mp4" type="video/mp4" />
      </video>
      <TypewriterText 
        text="The Colabi Foundation"
        subtext="we're in the middle of transformation"
      />
      {/* <SplitFlapCounter /> */}
    </div>
  );
}

export default App; 