import React, { useState, useEffect, useMemo } from 'react';
import './TypewriterText.css';
import { getFontByTime } from './fontSelector';

const TypewriterText = ({ text, subtext }) => {
  const [displayText1, setDisplayText1] = useState('');
  const [displayText2, setDisplayText2] = useState('');
  const [displayText3, setDisplayText3] = useState('');
  const [displaySubtext1, setDisplaySubtext1] = useState('');
  const [displaySubtext2, setDisplaySubtext2] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [subtextIndex, setSubtextIndex] = useState(0);
  const [selectedFont, setSelectedFont] = useState(getFontByTime());

  // Memoize the text processing to prevent unnecessary recalculations
  const { mainWords, firstLine, secondLine } = useMemo(() => {
    const mainWords = text.split(' ');
    const subtextWords = subtext.split(' ');
    const middleIndex = subtextWords.indexOf('of');
    const firstLine = subtextWords.slice(0, middleIndex).join(' ');
    const secondLine = subtextWords.slice(middleIndex).join(' ');
    return { mainWords, firstLine, secondLine };
  }, [text, subtext]);

  useEffect(() => {
    const totalLength = mainWords.reduce((acc, word) => acc + word.length, 0);
    
    const getWordAndPosition = (index) => {
      let currentLength = 0;
      for (let i = 0; i < mainWords.length; i++) {
        if (index < currentLength + mainWords[i].length) {
          return {
            word: mainWords[i],
            position: index - currentLength,
            wordIndex: i
          };
        }
        currentLength += mainWords[i].length;
      }
      return null;
    };

    if (currentIndex < totalLength) {
      const timeout = setTimeout(() => {
        const { word, position, wordIndex } = getWordAndPosition(currentIndex);
        if (wordIndex === 0) setDisplayText1(prev => prev + word[position]);
        if (wordIndex === 1) setDisplayText2(prev => prev + word[position]);
        if (wordIndex === 2) setDisplayText3(prev => prev + word[position]);
        setCurrentIndex(prev => prev + 1);
      }, 150);
      return () => clearTimeout(timeout);
    } else if (subtextIndex < firstLine.length) {
      const timeout = setTimeout(() => {
        setDisplaySubtext1(prev => prev + firstLine[subtextIndex]);
        setSubtextIndex(prev => prev + 1);
      }, 50);
      return () => clearTimeout(timeout);
    } else if (subtextIndex === firstLine.length) {
      // Reset displaySubtext2 when starting the second line
      setDisplaySubtext2('');
      setSubtextIndex(prev => prev + 1);
    } else if (subtextIndex > firstLine.length && subtextIndex <= firstLine.length + secondLine.length) {
      const timeout = setTimeout(() => {
        const secondLineIndex = subtextIndex - firstLine.length - 1;
        setDisplaySubtext2(prev => prev + secondLine[secondLineIndex]);
        setSubtextIndex(prev => prev + 1);
      }, 50);
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, subtextIndex, mainWords, firstLine, secondLine]);

  const isTyping = currentIndex < mainWords.join('').length || 
                  subtextIndex <= firstLine.length + secondLine.length;

  return (
    <div className="typewriter-container">
      <div className="typewriter-wrapper" style={{ fontFamily: selectedFont.style }}>
        <h1 className="typewriter-text">{displayText1}</h1>
        <h1 className="typewriter-text">{displayText2}</h1>
        <h1 className="typewriter-text">{displayText3}</h1>
        <h2 className="typewriter-subtext">{displaySubtext1}</h2>
        <h2 className="typewriter-subtext">{displaySubtext2}</h2>
        {isTyping && <span className="typewriter-cursor"></span>}
      </div>
    </div>
  );
};

export default TypewriterText; 